<template>
  <div class="CursosMain">
    <article class="cursos-sb-page">
      <div class="container">
        <br><br>
        <div class="cursos-container cursos-sb-page__wrapper">
          <h2 class="servicios-table__heading heading">Capacitaciones & cursos</h2><br>
          <section class="cursos-sb">
            <div
              v-for="(course,idxa) in cursos"
              :key="idxa"
            >
              <CursosPromo
                v-if="course.area=='promocion'"
                class="mb24 mt24"
                :title="course.title"
                :description="course.desc"
                :area="course.area"
              />
              <CursosListItem
                v-else
                :title="course.title"
                :description="course.desc"
                :area="course.area"
                :image="course.image"
                :fecha="course.fecha"
                :precio1="$nF(course.precio1)"
                :precio2="$nF(course.precio2)"
                :precio3="$nF(course.precio3)"
              />
              <br><br><br>
            </div>
          </section>
          <div class="cursos-notas">
            <strong>Notas:</strong>
            <ol>
              <li>Los precios listados pueden cambiar sin previo aviso.</li>
              <li>Se requieren por lo menos 5 personas para impartir un curso.</li>
              <li>Al final del curso se entrega una constancia con un registro de Secretaría del Trabajo y Previsión Social y tiene una vigencia de 1 año apartir de la fecha de impartición del curso.</li>
            </ol>
          </div>
          <br><br><br>
        </div>

      </div>
    </article>
  </div>
</template>

<script>
import CursosPromo from "@/components/cursos/CursosPromo.vue";
import CursosListItem from "@/components/cursos/CursosListItem.vue";
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
export default {
  name: "CursosMain",
  components: { CursosListItem, CursosPromo },
  mixins: [forms, dates],
  data() {
    return {
      cursos: [
        // { title: "R.C.P. en Bebés y Niños", desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis corrupti beatae tenetur nam recusandae expedita ipsa. Ut molestiae, hic quidem harum, quam ipsam eos totam repellat natus beatae, expedita rerum?", area: "Area Médica", precio1: 0, precio2: 1, precio3: 2, }, { title: "R.C.P. en Adultos", desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis corrupti beatae tenetur nam recusandae expedita ipsa. Ut molestiae, hic quidem harum, quam ipsam eos totam repellat natus beatae, expedita rerum?", area: "Area Médica", precio1: 0, precio2: 1, precio3: 2, }, { title: "R.C.P. Integral", desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis corrupti beatae tenetur nam recusandae expedita ipsa. Ut molestiae, hic quidem harum, quam ipsam eos totam repellat natus beatae, expedita rerum?", area: "Area Médica", precio1: 0, precio2: 1, precio3: 2, }, { title: "6 Acciones para Salvar una Vida. Nivel 1", desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis corrupti beatae tenetur nam recusandae expedita ipsa. Ut molestiae, hic quidem harum, quam ipsam eos totam repellat natus beatae, expedita rerum?", area: "Area Médica", precio1: 0, precio2: 1, precio3: 2, }, { title: "Primeros Auxilios Pediátricos", desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis corrupti beatae tenetur nam recusandae expedita ipsa. Ut molestiae, hic quidem harum, quam ipsam eos totam repellat natus beatae, expedita rerum?", area: "Area Médica", precio1: 0, precio2: 1, precio3: 2, },
        // {
        //   area: "promocion",
        //   title:
        //     "Todos los cursos cuentan con 10% de descuento si te preinscribes desde nuestro sitio web.",
        //   desc: "La promoción aplica tanto para aquellas personas que deseen capacitarse de forma individual o grupal.",
        //   img: "",
        // },
        {
          title: "Primer Respondiente",
          desc: "Ser primer respondiente es tener los conocimientos, habilidades y destrezas para identificar, evaluar e intervenir en situaciones de urgencia o emergencia médica.",
          area: "Area Médica",
          image: "primer-respondiente.jpg",
          // fecha: "2022-09-17",
          precio1: 800,
          precio2: 750,
          precio3: 700,
        },
        {
          title: "Primeros Auxilios Pediátricos",
          desc: "Aprende a identificar, tratar y/o prevenir situaciones de emergencia frecuentes que puede sufrir un niño de cualquier edad.",
          area: "Capacitación en Area Médica",
          image: "pediatricos.png",
          precio1: 800,
          precio2: 750,
          precio3: 700,
        },
        {
          title: "Prevención y Combate vs. Incendios",
          desc: "Adopción de diversas medias para salvaguardar personas y de bienes con la pronta supresión del incendio mediate mecanismos y agentes de extinción adecuados.",
          area: "Capacitación en Area de Rescate",
          // fecha: "2022-07-29",
          image: "combate-incendios.jpg",
          precio1: 800,
          precio2: 750,
          precio3: 700,
        },

        // { title: "Evacuación Guiada de Inmuebles", desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis corrupti beatae tenetur nam recusandae expedita ipsa. Ut molestiae, hic quidem harum, quam ipsam eos totam repellat natus beatae, expedita rerum?", area: "Area de Seguridad", precio1: 0, precio2: 1, precio3: 2, }, { title: "Combate Contra Incendios", desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis corrupti beatae tenetur nam recusandae expedita ipsa. Ut molestiae, hic quidem harum, quam ipsam eos totam repellat natus beatae, expedita rerum?", area: "Area de Rescate", precio1: 0, precio2: 1, precio3: 2, }, { title: "Rescate en Alturas", desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis corrupti beatae tenetur nam recusandae expedita ipsa. Ut molestiae, hic quidem harum, quam ipsam eos totam repellat natus beatae, expedita rerum?", area: "Area de Rescate", precio1: 0, precio2: 1, precio3: 2, }, { title: "Materiales Peligrosos", desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis corrupti beatae tenetur nam recusandae expedita ipsa. Ut molestiae, hic quidem harum, quam ipsam eos totam repellat natus beatae, expedita rerum?", area: "Area de Rescate", precio1: 0, precio2: 1, precio3: 2, }, { title: "Busqueda y Rescate en Espacios Confinados", desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis corrupti beatae tenetur nam recusandae expedita ipsa. Ut molestiae, hic quidem harum, quam ipsam eos totam repellat natus beatae, expedita rerum?", area: "Area de Rescate", precio1: 0, precio2: 1, precio3: 2, }, { title: "Tecnicas de Rescate en Estructuras Colapsadas", desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis corrupti beatae tenetur nam recusandae expedita ipsa. Ut molestiae, hic quidem harum, quam ipsam eos totam repellat natus beatae, expedita rerum?", area: "Area de Rescate", precio1: 0, precio2: 1, precio3: 2, },
      ],
      requisitos: [
        "Tener entre 18 y 45 años",
        "Acta de nacimiento",
        "Idenficiacion oficial",
        "Comprobante de domicilio",
        "Curp",
        "Certificado de secundaria",
        "Certificado de preparatoria",
        "Certificado médico emitido por Cruz Roja",
        "Constancia de no antecedentes penales",
        "3 fotografias a color tamaño infantil con playera blanca y cuello redondo",
        "Buena salud física",
        "Buena salud mental",
        "Manejo de situaciones bajo presión",
        "Actitud de servicio",
        "Disciplina",
      ],
      showDialog: false,
      validando: false,
      showAlert: false,
      form: {
        nombre: null,
        telefono: null,
        fecha: null,
      },
      errors: {
        nombre: null,
        telefono: null,
        fecha: null,
      },
      srcImg: null,
      theImage: null,
    };
  },
  methods: {
    _validateFecha() {
      this.errors.fecha = this.DateTime.fromFormat(
        this.form.fecha,
        "dd-MM-yyyy"
      ).isValid
        ? null
        : "Ingresa una fecha válida en formato día-mes-año";
      // console.dir(this._isValidDate(this.form.fecha));
    },
    _enviarMensajeCurso() {
      this.validando = true;
      if (this._validateAll("form")) {
        this.showAlert = false;
        const data = {
          type: "capacitacion",
          page: this.title,
          name: this.form.nombre,
          phone: this.form.telefono,
          birthdate: this.form.fecha,
          route: this.$route.fullPath,
          device: this.$device,
        };
        this._saveAll("leads", data, "msg", "_handleLeadResponse");
        this.validando = false;
      } else {
        this.showAlert = true;
        this.validando = false;
      }
    },
    _handleLeadResponse(res) {
      this.$router.push("/gracias/capacitacion");
    },
  },
};
</script>
