<template>
  <div class="CursosPromo cursos-sb__item cursos-sb__item--vertical">
    <div class="cursos-sb__pic">
      <img
        src="@/assets/promocion.png"
        alt="Promocion"
      >
    </div>
    <div class="cursos-sb__content">
      <div
        class="cursos-sb__area"
        v-if="area"
      >
        <span
          class="area"
          v-text="area"
        />
      </div>
      <span
        v-if="title"
        class="cursos-sb__title"
        target="_blank"
        v-text="title"
      />
      <div
        v-if="description"
        class="cursos-sb__text"
        v-text="description"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "CursosPromo",
  props: {
    title: String,
    image: String,
    area: String,
    description: String,
  },
};
</script>