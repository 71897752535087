<template>
  <div class="CursosListItem cursos-sb__item">
    <div
      class="cursos-sb__pic"
      v-if="image"
    >
      <img
        :srcset='require("@/assets/capacitacion/" + this.image)'
        alt=""
      >
    </div>
    <div class="cursos-sb__content">
      <div
        class="cursos-sb__area"
        v-if="area"
      >
        <span
          class="area"
          v-text="area"
        />
      </div>
      <div
        class="cursos-sb__title"
        target="_blank"
      >
        {{title}}
        <span
          v-if="fecha"
          class="cursos-sb__area"
        ><br>Se impartirá el {{_doDiaMes(fecha)}}</span>
      </div>
      <div
        class="cursos-sb__text"
        v-text="description"
      />
      <div class="cursos-sb__area">
        Inversión
      </div>
      <table
        with="100%"
        class="mb8"
        style="text-align: center;"
      >
        <tr style="background-color: #DAE4E5;">
          <th
            class="p4"
            width="33%"
          >1 a 10 <v-icon color="#232323">person</v-icon>
          </th>
          <th
            class="p4"
            width="34%"
          >
            11 a 30 <v-icon color="#232323">person</v-icon>
          </th>
          <th
            class="p4"
            width="33%"
          >
            31 o más <v-icon color="#232323">person</v-icon>
          </th>
        </tr>
        <tr>
          <td class="p4">{{precio1}}*</td>
          <td class="p4">{{precio2}}*</td>
          <td class="p4">{{precio3}}*</td>
        </tr>
      </table>
      <small style="font-size:11px;text-align:right!important">*precio por persona</small>
      <button
        class="th-hero-slider__btn btn btn--technical mt24 mb24"
        @click="showDialog=true"
      >¡Deseo inscribirme!</button>
      <br><br>
    </div>
    <v-dialog
      width="500"
      v-model="showDialog"
    >
      <v-card
        :disabled="cargando || validando"
        :loading="cargando"
      >
        <v-card-title>
          Inscripción al curso {{title}}
        </v-card-title>
        <v-card-title v-if="showAlert">
          <span class="isAlert">
            Por favor llena los campos marcados con <strong>*</strong>
          </span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            filled
            label="Nombre completo*"
            hint="Será usado para la constancia que te entregaremos"
            v-model="form.nombre"
            :error-messages="errors.nombre"
          />
          <v-text-field
            filled
            label="Fecha de nacimiento*"
            hint="día-mes-año"
            placeholder="dd-mm-aaaa"
            pattern="\d*"
            v-mask="'##-##-####'"
            v-model="form.fecha"
            :error-messages="errors.fecha"
            @blur="_validateFecha"
          />
          <v-text-field
            prepend-inner-icon="phone"
            filled
            label="Teléfono*"
            hint="Ingresa tu teléfono a 10 dígitos"
            v-mask="'###-###-####'"
            pattern="\d*"
            v-model="form.telefono"
            :error-messages="errors.telefono"
          />
          <!-- <v-select
            filled
            label="¿En que horario te podemos contactar?*"
            :items="[ 'En cualquier hora del día','Entre 9am y 11am', 'Entre 12pm y 2pm', 'Entre 3pm y 6pm', ]"
            v-model="form.horario"
            :error-messages="errors.horario"
          /> -->
          <v-btn
            block
            color="primary"
            @click="_enviarMensajeCurso"
            :disabled="cargando || validando"
            :loading="cargando"
          >Enviar e inscribirme <span style="font-size:1.24rem">&#8594;</span></v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            small
            @click="showDialog=false"
          >Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";
export default {
  name: "CursosListItem",

  props: {
    title: String,
    image: String,
    area: String,
    fecha: String,
    description: String,
    precio1: [String, Number],
    precio2: [String, Number],
    precio3: [String, Number],
  },
  components: { DatePicker },
  mixins: [forms, dates],
  data() {
    return {
      showDialog: false,
      validando: false,
      showAlert: false,
      form: {
        nombre: null,
        telefono: null,
        fecha: null,
      },
      errors: {
        nombre: null,
        telefono: null,
        fecha: null,
      },
      srcImg: null,
      theImage: null,
    };
  },
  methods: {
    _validateFecha() {
      this.errors.fecha = this.DateTime.fromFormat(
        this.form.fecha,
        "dd-MM-yyyy"
      ).isValid
        ? null
        : "Ingresa una fecha válida en formato día-mes-año";
      // console.dir(this._isValidDate(this.form.fecha));
    },
    _enviarMensajeCurso() {
      this.validando = true;
      if (this._validateAll("form")) {
        this.showAlert = false;
        const data = {
          type: "capacitacion",
          page: this.title,
          name: this.form.nombre,
          phone: this.form.telefono,
          birthdate: this.form.fecha,
          route: this.$route.fullPath,
          device: this.$device,
        };
        this._saveAll("leads", data, "msg", "_handleLeadResponse");
        this.validando = false;
      } else {
        this.showAlert = true;
        this.validando = false;
      }
    },
    _handleLeadResponse(res) {
      this.$router.push("/gracias/capacitacion");
    },
  },
  watch: {
    showDialog(a) {
      if (!a) {
        this._limpiarCampos("form");
        this.showAlert = false;
      }
    },
  },
};
</script>
<style lang="scss">
.p4 {
  padding: 4px;
}
</style>